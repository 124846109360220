import React from "react"
import BlogTitle from "../../components/BlogTitle"
import Container from "../../components/Container"
import Hero from "../../components/Hero"
import HeroLead from "../../components/HeroLead"
import HeroTitle from "../../components/HeroTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import Text from "../../components/Text"
import links from "../../content/links"
import metaImg from "../../images/from-idea-to-app.svg"

export default (): JSX.Element => (
  <Layout>
    <SEO
      title="Ideasta mobiilisovellukseksi"
      description="Tässä artikkelissa käymme läpi, mitä askeleita sinun tulee ottaa, jotta saat luotua omasta ideastasi menestyvän sovelluksen."
      pageImage={metaImg}
      pageUrl={links.blogSites.fromIdeaToApp}
    />

    <section id="hero">
      <Hero hideContact>
        <HeroTitle>
          <h1>
            <span className="block">Ideasta</span>
            <span className="block text-indigo-400">mobiilisovellukseksi</span>
          </h1>
        </HeroTitle>
        <HeroLead>Kirjoittanut: Jaakko Lamminpää</HeroLead>
      </Hero>
    </section>

    <section>
      <Container>
        <Text>
          Mobiilisovellukset ovat valtaamassa markkinoita ja mobiilisovelluksen
          kehittäminen on varmasti käynyt yhä useamman mielessä.
          Mobiilisovellusten on arvioitu kasvavan yli 6 triljoonan euron
          markkinaksi vuoteen 2022 mennessä. Siksi onkin ehkä aika pohtia, miten
          omasta ideasta saadaan tuotettua mobiilisovellus.
        </Text>
        <Text>
          Käytännössä sovelluksen tekeminen vastaa nykyisen liiketoiminnan
          muuttuvaan tai kasvavaan kysyntään tai sillä voidaan luoda uutta
          liiketoimintaa. Moni yritys on lähtenyt sovelluskehitykseen
          laajentamalla omaa konseptiaan, kuten Netflix ja Facebook. Toiset ovat
          puolestaan astuneet täysin uudelle markkina-alueelle, kuten Uber.
        </Text>
        <Text>
          Yleensä mobiiliapplikaatiot lähtevät liikkeelle ilmaan heitetystä
          ideasta tai ajatuksella leikittelystä. Ideointivaiheessa suurta
          stressiä ei tarvitsekaan ottaa, vaan olennaisempaa on pohtia ideoita
          laidasta laitaan. Markkinat kuitenkin muistuttavat kasvavasta ja
          tiukentuvasta kilpailutilanteesta. Kun on aika toimia ja luoda
          sovellus markkinoille, tulee siihen sitoutua sen mukaisesti.
        </Text>
        <Text>
          Tässä blogissa käymmekin läpi, mitä askeleita sinun tulee ottaa, jotta
          saat luotua omasta ideastasi menestyvän sovelluksen.
        </Text>
        <BlogTitle>Kirjoita ideoita muistiin</BlogTitle>
        <Text>
          Aloita laatimalla lista mahdollisista sovelluksista. Luonnostele
          ideasi eli pohdi mitä kaikkea se voisi ylipäätään tehdä. Alussa ei
          kannata liikaa murehtia, onko sille kysyntää, vastaako se tiettyyn
          tarpeeseen tai onko se edes järkevä. Tärkeintä on, että kirjoitat
          kaikki ideasi vihkoon tai vaikka google docsiin. Koskaan ei tiedä
          milloin jokin villeistä ideoista voikin osoittautua arvokkaaksi.
        </Text>
        <Text>
          Älä turhaan pohdi vielä sovelluksen rakennetta pidemmälle. Yritä siis
          vain keksiä ideoita, johon mobiilisovellusta voidaan käyttää. Mitä
          sovellus tekee pääpiirteissään? Tähän pitäisi kyetä vastaamaan yhdellä
          lauseella. Näin pidät ideat avoimina, etkä mieti tässä vaiheessa vielä
          liian pitkälle. Minkä ongelman minä haluan ratkaista? Haluanko tarjota
          palveluita? Haluanko luoda yhteisön, joka yhdistää? Tällaisten
          kysymysten avulla voit laajentaa ideoitasi entisestään.
        </Text>
        <BlogTitle tag="h3">Pohdi mitä ongelmia ja tarpeita on</BlogTitle>
        <Text>
          Älä tyydy vain muutamaan ideaan – kirjoita niitä niin paljon kuin
          pystyt. Jos seinä tuntuu tulevan vastaan, voit pohtia mitä ongelmia ja
          tarpeita ihmisillä on. Mutta älä tee tätä ennen kuin olet oikeasti
          antanut kaikkesi.
        </Text>
        <Text>
          Kun olet saanut vähintään sivun verran mahdollisia sovelluksia, on
          aika kehittää tuotetta askeleen verran pidemmälle. On tullut aika
          hienosäätää ja karsia ideoita. Osa listan ideoista voi olla helppo
          karsia pois, toiset voivat vaatia enemmän pohdintaa. Osaa voidaan
          parantaa hienosäätämällä ja luoda uusia mahdollisia käyttökohteita.
          Lopputuloksena sinulla pitäisi olla kourallinen ideoita ennen
          seuraavaa askelta.
        </Text>
        <BlogTitle>Taustatutkimus</BlogTitle>
        <Text>
          Varteenotettavimmista sovellusideoista kannattaa tehdä perusteellinen
          taustatutkimus, jonka avulla listalle jäävät enää mahdolliset
          menestyjät. Tärkeimpänä ovat markkinatutkimus ja sovelluksen
          vaatimukset. Lupaavatkin sovellukset voivat epäonnistua mikäli
          taustatutkimusta ei ole tehty huolella.
        </Text>
        <BlogTitle tag="h3">Kilpailijat</BlogTitle>
        <Text>
          Koska sovellusmarkkinoille on tunkua, on ainutlaatuisten
          applikaatioiden luominen vaikeaa ja arvokasta. Ensimmäisenä kannattaa
          tarkistaa mahdolliset kilpakumppanit. Mikäli olet ensimmäisenä
          markkinoilla, hienoa! Entä jos jokin sovellus on jo vähintäänkin
          osittain samalla saralla? Ei huolta. Se voi myös kertoa, että
          kyseiselle sovellukselle on kysyntää. Mikäli vastaava applikaatio on
          jo olemassa, sinun tuleekin miettiä, voitko omalla ideallasi erottua
          kilpailijoistasi ja kerätä sovelluksellesi uskollisia käyttäjiä. Hyvä
          keino on tutustua kilpailijoiden arvosteluihin ja palautteeseen. Pyri
          löytämään mitä heiltä puuttuu ja mikä erottaa sinut joukosta.
        </Text>
        <BlogTitle tag="h3">Asiakkaat</BlogTitle>
        <Text>
          Tästä pääsemmekin asiakkaisiin. Kenelle sovellus on kehitetty? Onko
          sillä rajattu käyttäjäryhmä vai tavoitteletko mahdollisimman laajaa
          yleisöä? Rajatun käyttäjäryhmän tavoittaa huomattavasti helpommin ja
          tehokkaammin. Taustatutkimuksen avulla voit selvittää, mitä he
          odottavat sovellukselta ja pyrkiä vastaamaan heidän tarpeisiinsa jo
          suunnitteluvaiheessa. Asiakkaita voi jaotella muun muassa sukupuolen,
          asuinpaikan, iän, tulotason tai perhestatuksen mukaan. Tuntemalla
          asiakkaasi, voit vastata heidän tarpeisiinsa kohdistetusti. Samalla se
          helpottaa kehittämisprosessia huomattavasti. Jos tiedät mitä
          asiakkaasi haluavat, on testausvaiheessa käytössä jo todennäköisemmin
          huomattavasti valmiimpi ja toimivampi sovellus eikä suuria muutoksia
          tarvita enää myöhäisemmässä kehitysvaiheessa.
        </Text>
        <BlogTitle tag="h3">Markkinoilla liikkuva raha</BlogTitle>
        <Text>
          Viimeisenä, muttei vähäisimpänä sinun tulee perehtyä markkinoiden
          tarpeeseen ja siellä liikkuvaan rahaan. Tämä on pohjimmiltaan
          yhdistelmä kahdesta aikaisemmasta. Ne määrittävät onko tuotteellasi
          kysyntää ja ollaanko siitä todella valmiita maksamaan. Toinen tärkeä
          pohdittava on, miten sovelluksesta saadaan tuottoa. Onko kohdeyleisö
          valmis maksamaan sovelluksista? Voiko tuottoa saada mainoksilla? Vai
          oletko sovelluksella myymässä esimerkiksi palveluita? Yleensä jopa
          hienotkin ideat kaatuvat kannattamattomuuteen. Liian pieni kohderyhmä
          yhdistettynä jo valmiisiin ilmaisiin sovelluksiin heikentää
          huomattavasti sovelluksen tuottavuutta.
        </Text>
        <BlogTitle>Valitse sovellus joka koskettaa sinua</BlogTitle>
        <Text>
          Tämä kuulostaa kliseeltä, mutta on tärkeää, että olet intohimoinen
          tekemästäsi. Idean on täytynyt olla sinulle mieleenpainuva, jotta se
          voisi olla kiinnostava myös muidenkin mielestä. Tässä ei ole mitään
          muuta ihmeellisempää takana. Etsi se idea, joka nousee aina mieleesi
          kun katsot listaasi. Valitse tämä ja toimi.
        </Text>
        <BlogTitle>Rahoituksen saaminen ja rahan tekeminen</BlogTitle>
        <Text>
          Vaikka yhteisen hyvänkin tekeminen voi olla motivoivaa, yleensä
          motivoivampaa on rahallisen voiton saavuttaminen. Rahoituksen saaminen
          ja rahan tekeminen menevät pitkälti käsi kädessä ja nojaavat samoihin
          perusajatuksiin. Samat ideat jotka tuottavat rahaa, ovat myös
          kannattava perusta rahoituksen hakemiselle. Sijoittajat eivät ole
          valmiita rahoittamaan ideoita, jotka eivät ole tuottamassa voittoa.
          Kysy itseltäsi, millä asteella haetaan rahoitusta? Mitä suurempi
          sovellus, sitä suuremmat kulut, ja sitä parempi täytyy esityksen olla
          rahoitusta haettaessa.
        </Text>
        <Text>
          Meidän yrityksemme tuottaa valmiita sovelluksia noin 30 000 euron
          hintaan. Mikäli olet tuottamassa äärimmäisen monimutkaista ja laajalle
          levittäytyvää sovellusta, voivat kustannukset nousta hieman
          entisestään. Suurempaa rahoitusta hakiessa on hyvä olla valmiina
          esimerkiksi toimiva prototyyppi tai rahoitusta voi hakea ensin
          pelkälle prototyyppille. Sen luominen maksaa noin 5 000 euroa, mutta
          sen päälle voidaan jatkaa kehittämistä ja se on todiste, että
          applikaatio toimii myös todellisuudessa.
        </Text>
        <Text>
          Millä argumenteilla voin sitten pitchata tai myydä idean? Sijoittajan
          ensimmäinen kysymys on: miten minä saan tästä rahaa? Sovellus voi
          tuottaa rahaa kolmella tavalla. Käyttäjä maksaa ostaessaan
          sovelluksen, sovellus itsessään myy jotain, tai sovelluksessa
          näytetään mainoksia. Mikään näistä ei kuitenkaan ole kannattavaa, jos
          et pysty vakuuttamaan, että sovelluksellesi on myöskin tarvetta,
          kysyntää ja käyttäjiä. Tuottavuuden muoto riippuu paljolti itse
          sovelluksesta, eikä siihen voi antaa mitään suoraa toimivaa kaavaa.
        </Text>
        <Text>
          Ainoa yleinen totuus lienee, että hintavammat tai edes kohtuu
          edulliset sovellukset eivät ole usein enää kannattavia. Siksi suurin
          osa tarjoaakin mobiiliapplikaatioitaan ilmaiseksi tai äärimmäisen
          edullisesti ja raha tehdään sovelluksessa tapahtuvilla pienillä
          ostoksilla. Mainokset ovat hyvä tapa tienata, mutta osalle käyttäjistä
          ne aiheuttavat päänsärkyä. Siksi ehkä suurimmat ja näyttävimmät
          mainokset kannattaa jättää pois. Hyvä mahdollisuus on myös yhdistää
          näitä ominaisuuksia ja esimerkiksi testausvaiheessa valita näistä
          sitten paras yhdistelmä.
        </Text>
        <BlogTitle>Hahmottele sovellus</BlogTitle>
        <Text>
          Mikäli haet sovelluksellesi erillistä rahoitusta on kannattavaa tehdä
          tämä askel ennen tuotteen esittelyä sijoittajille. Näin sinulla on
          selkeä malli esitettäväksi ja rahoituksen saaminen on huomattavasti
          helpompaa kuin puoliksi pohditun idean esittämisellä. Hahmottele siis
          sovelluksesi – tai ainakin ensimmäinen malli siitä. Harvoin
          ensimmäisellä tai edes toisella kerralla luotu malli on vielä
          julkaisemisen arvoinen. Timantin hiominen vie aikaa. Kyseenalaista
          ideasi ja muokkaa näkemystäsi usein. Kysy myös muilta mielipiteitä, on
          liian helppoa uppoutua omiin uskomuksiin. Ei myöskään riitä, että olet
          luonut idean, vaan keskeisenä aatteena on pohtia mihin sovelluksesi
          tulisi pystyä teknisesti.
        </Text>
        <Text>
          Hahmottelukin kannattaa aloittaa luomalla lista. Mieti, mitä kaikkia
          ominaisuuksia sovellukseltasi vaadit. Taas aloita kirjoittamalla
          kaikki mitä mieleesi tulee. Voit myös kirjoittaa alkuperäistä ideaasi
          uudestaan uusin sanoin ja uudesta näkökulmasta. Näin saat myös
          helposti laajennettua listaa mahdollisista ominaisuuksista. Näitä
          kannattaa myös työstää yhdessä yrityskumppaneiden, suunnittelijoiden,
          sijoittajien ja kehittäjien kanssa. Tosin suositeltavaa on
          allekirjoituttaa heillä salassapitosopimus, jotta tuotettasi ei
          varasteta. Tärkeintä on erottaa listasta ominaisuudet, jotka ovat
          ainutlaatuisia ja tekevät siitä suositun.
        </Text>
        <BlogTitle tag="h3">Wireframen rakentaminen</BlogTitle>
        <Text>
          Tärkeintä on kyetä luomaan wireframe eli rautalankamalli, joka voidaan
          esittää kehittäjälle. Mitä yksityiskohtaisempi se on, sitä helpompaa
          kehittämistyö on. Näin saat juuri sellaisen sovelluksen kuin haluat.
          Mikä tärkeämpää, se säästää aikaa ja rahaa.
        </Text>
        <BlogTitle tag="h4">Kuinka rakennan wireframe mallin?</BlogTitle>
        <Text>
          Asioiden listaaminen on helppoa, mutta samalla erittäin
          suurpiirteistä. Sinne voisi tulla yläkulmaan ehkä jonkinlainen
          valikko. Jos se vaikka tallentaisi jotain asioita jonnekin muistiin.
          Jonkinlainen aloitustekstikin olisi kiva. Paras tapa saada myös
          itsellesikin selkeä näkemys on piirtämällä. Tähän käy ihan tavallinen
          kynä ja paperi tai sitten voit käyttää jopa tietokoneen
          piirrustusohjelmia. Koneen avulla voit esimerkiksi ottaa kuvan
          haluamastasi mallista ja liittää sen osaksi kuvausta. Näin voit
          selkeästi ja varmasti esitellä kokonaisuuden, joka vastaa omaa
          näkemystäsi.
        </Text>
        <Text>
          Lisäksi voit kirjoittaa tarkentavia kuvauksia piirrettyyn malliin. Jos
          haluat esimerkiksi painikkeen, jolla on tietty ominaisuus, sen
          kannattaa kirjoittaa ylös. Kaikista ominaisuuksista ja selkeästä
          visiosta huolimatta kannattaa silti muistaa kuunnella muita projektiin
          osallistuvia. Usein oma näkemys on itselle äärimmäisen rakas ja se
          saattaa estää näkemästä metsää puilta. Siksi suunnitteluvaiheessa
          kannattaa myös huomioida saatu palaute esimerkiksi suunnittelijoilta
          ja mahdollisilta kehittäjiltä.
        </Text>
        <BlogTitle tag="h4">Älä rohmua kaikkia ominaisuuksia</BlogTitle>
        <Text>
          Kun alkaa pohtimaan applikaatioon vaadittavia ominaisuuksia, on
          helposti kiusauksena sisällyttää mukaan kaikkea mahdollista. Ihan vain
          siltä varalta, että se vastaisi kaikkien tarpeisiin ja tekisi kaiken
          mahdollisen. Lopputuloksena on tällöin kuitenkin sillisalaatti –
          sekava sovellus, joka tekee paljon asioita, mutta ei yhtään kunnolla.
          Siksi tässä vaiheessa kannattaa aluksi pysyttäytyä vain
          välttämättömissä ominaisuuksissa. Muutaman lisäominaisuuden lisääminen
          saattaa vaikuttaa pieneltä, mutta kannattaa tarkoin miettiä, mitä
          todella tarvitset. Kokemuksesta voimme kertoa, että lisättyihin
          ominaisuuksiin kuluu yllättävän paljon aikaa. Vaikka luot alussa
          yksinkertaisen sovelluksen, se ei tarkoita, etteikö sitä voisi
          myöhemmin kehittää lisää. Tärkeintä on tässä vaiheessa saada toimiva
          ja käyttäjäystävällinen kokonaisuus.
        </Text>
        <BlogTitle tag="h4">Rakenna brändisi</BlogTitle>
        <Text>
          Tämä on myös se vaihe suunnittelussa, jossa luot oman brändisi – sen
          mikä erottaa sinut kilpailijoista. Vaikka lukemalla kilpailijoiden
          arvosteluja voit luoda toimivamman sovelluksen, kannattaa silti
          käyttää aikaa miettimiseen. Millaisen kokonaisuuden haluat luoda?
          Miten se tulee jatkossa kehittymään? Imago on tärkeä osa käyttäjien
          sitoutumista applikaation käyttöön.
        </Text>
        <BlogTitle tag="h4">Entä jos suunnittelu ei onnistu?</BlogTitle>
        <Text>
          Jos suunnittelu tuottaa hankaluuksia, ei sen kanssa tarvitse painia
          yksin. Voit pallotella ideaa ystävien kanssa tai keskustella
          tarvittavista ominaisuuksista muiden yrityskumppanien kanssa. Voit
          myös keskustella kehittäjän kanssa sovelluksen kehittämisestä aivan
          prosessin alkumetreiltä alkaen.
        </Text>
        <BlogTitle>Kehitä sovellus</BlogTitle>
        <Text>
          Rautalankamallin pohjalta kehität sovelluksesi, joka on
          käyttäjäystävällinen ja täyttää kaikki siltä vaaditut
          perusominaisuudet. Sovelluksen kehittäminen on muutakin kuin
          kehittäjien koodinkirjoittamista. Osana kehittämistä on sovelluksen
          testaaminen, jolla varmistetaan sen toimivuus ja jonka avulla sitä
          voidaan kehittää entisestään. Tämä palaa aikaisempaan, jolloin
          kerroimme, että timantti vaatii hiomista – paljon hiomista. Vaikka
          haluna onkin usein työntää tuote markkinoille nopeasti, kannattaa
          kehitystyö tehdä huolella.
        </Text>
        <BlogTitle tag="h3">Toteutus</BlogTitle>
        <Text>
          Ensimmäisenä sinun tulee valita mille alustalle sovellus julkaistaan.
          Käytännössä valittavana ovat iOS- ja Android-alustat, jotka kattavat
          99 prosenttia puhelinten käyttöjärjestelmistä. Puhutaan siis Applen
          puhelimista ja muiden valmistajien puhelimista. Molemmilla on oma
          koodikielensä ja molemmille kääntämisellä on etunsa. Meidän
          yrityksemme valttina on tarjolla oleva REACT native kieli, jolla
          pystymme lähes samalla hinnalla tuottamaan sovelluksen molemmille
          alustoille.
        </Text>
        <BlogTitle tag="h4">Lähesty yritystä</BlogTitle>
        <Text>
          Seuraavaksi sinun tulee lähestyä kehittäjiä tai yritystä, jos et ole
          tätä aikaisemmin tehnyt. Kuinka valitsen sopivan yrityksen? Tämä
          tapahtuu itse kartoittamalla tarjolla olevia mahdollisuuksia. Hae noin
          5 sopivalta vaikuttavaa yritystä, kirjoituta heillä salassapitosopimus
          ja lähetä heille tarkemmat tiedot projektistasi. Hyvä kumppani arvioi
          tuotettasi ja esittää varmasti paljon kysymyksiä. Näihin kannattaa
          vastata huolella, jotta ideasi välittyy varmasti heille. Hyvä
          kehittäjä pystyy tarjoamaan kehitysehdotuksia sekä tekemään hinta- ja
          aika-arvion. Näin voit arvioida eri yritysten hintoja, aikataulua ja
          ennen kaikkea heidän ideoitaan eli sitoutumistaan sovelluksen
          kehittämiseen. Näiden perusteella valinnan tulisi olla kohtuullisen
          helppoa.
        </Text>
        <BlogTitle tag="h4">Sovellus luodaan yhteistyössä</BlogTitle>
        <Text>
          Itse sovelluskehitys tapahtuu yhteistyössä sinun, suunnittelijoiden ja
          kehittäjien kanssa. Yleensä yrityksillä on ihmisiä, jotka kykenevät
          suunnittelemaan visuaalista ilmettä sekä kehittämään
          käyttäjäystävällistä sovellusympäristöä. Yrityksen tulee olla
          yhteydessä asiakkaaseen ja tarjota asiakkaalleen päivityksiä
          kehityksestä. Aina ei tietenkään tapahdu suuria askelia, sillä
          toisinaan visuaalisen ilmeen ja koodin kehittäminen vie oman aikansa.
          Sinun tulisi pystyä vapaasti kyselemään tapahtuvasta kehityksestä
          kehityksen jokaisessa vaiheessa. Me emme ainoastaan suosittele, vaan
          myös vaadimme sitä. Näin olemme kaikki samalla sivulla ja pystymme
          kehittämään parhaan mahdollisen tuotteen.
        </Text>
        <Text>
          Mikäli kehität uusia mielestäsi tarpeellisia ominaisuuksia, voit
          keskustella niiden lisäämisestä suunnitteilla olevaan versioon.
          Sovelluskehittäjä kykenee vähintäänkin antamaan aika ja hinta-arviot,
          jolloin voit päättää toteutetaanko ne heti, vai säästetäänkö ne
          myöhempää kehitystä varten.
        </Text>
        <BlogTitle tag="h4">Testaus</BlogTitle>
        <Text>
          Kun kehitystyö on valmis, on aika testata applikaatiota
          potentiaalisilla asiakkailla. Näytä heille miten sovellus toimii ja
          selitä sen ominaisuudet heille. Kohderyhmä kannattaa aina pitää
          tarkkaan mielessä, kun applikaatiota testataan. He päättävät kuinka
          hyvin sovelluksesi otetaan vastaan. Ole siis avoin mahdollisille
          kehitysehdotuksille ja ole valmis työstämään tuotetta vielä
          testaamisenkin jälkeen.
        </Text>
        <BlogTitle>Julkaise sovellus</BlogTitle>
        <Text>
          Kun kehitystyö on vihdoin ohi, on tullut aika julkaista sovellus.
          Sovellusta ei voi vain julkaista ja odottaa suosiota. Se pitää myydä
          käyttäjille. Tässä kohtaa on hyvä palata pohtimaan valittua
          kohderyhmää. Missä mainostan? Miten mainostan? Nuoremmat on helppo
          tavoittaa sosiaalisesta mediasta, mutta erilaisille käyttäjäryhmille
          on hyvä luoda markkinointistrategioita.
        </Text>
        <BlogTitle tag="h3">Markkinointistrategia ja markkinointi</BlogTitle>
        <Text>
          Käytännössä sovellus julkaistaan iOS tuotteilla App Storessa ja
          Android-puhelimilla Google Play-kaupassa. Markkinointia suunnitellessa
          on hyvä mahdollisuuksien mukaan konsultoida sovellusmarkkinoinnin alan
          ammattilaisia. Voit myös markkinoida sovellusta itse. Aloita
          Facebookilla, Twitterillä ja Instagrammilla. Voit myös lähestyä
          toimittajia ja bloggaajia, jotka voivat olla kiinnostuneita
          sovelluksestasi. Mikäli budjettisi sallii, voit käyttää myös
          PR-yrityksiä tai applikaatioita mainostavia yrityksiä. Näin saat
          varmasti parhaan mahdollisen näkyvyyden itsellesi.
        </Text>
        <BlogTitle>Toista aikaisemmat vaiheet</BlogTitle>
        <Text>
          Kun kaikki näyttää valmiilta, et saa jäädä laakereillesi lepäämään.
          Sovellus on nyt käytössä ja monesti saat kerättyä paljon enemmän
          käyttäjädataa. Mikäli saat positiivista palautetta on aika
          valmistautua seuraavaan vaiheeseen. Toista kaikki askeleet 1-8.
          Toistamalla aikaisemmat vaiheet, voit alkaa rakentamaan valmiin
          toimivan sovelluksen päälle lisää. Samalla rakennat ja kehität jälleen
          brändiäsi. Tällä kertaa suoriudut prosessista todennäköisesti
          huomattavasti nopeammin ja tehokkaammin. Jos kuitenkin applikaatiosi
          ei ottanut tuulta alleen, selvitä mitkä tekijät vaikuttivat siihen ja
          suunnittele vaadittavat jatkotoimet.
        </Text>
      </Container>
    </section>
  </Layout>
)
